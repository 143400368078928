<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1 style="text-align: center">CTT Notas de Entrega</h1>
    <div class="container form">
      <div class="form-group">
        <label for="date">Data Inicial</label>
        <input
          type="date"
          class="form-control"
          id="date"
          v-model="date_ini"
          required
        />
        <label for="date">Data Final</label>
        <input
          type="date"
          class="form-control"
          id="date"
          v-model="date_fin"
          required
        />
        <button
          class="btn btn-primary"
          style="margin-top: 10px"
          @click="getAllDeliveryNotes"
        >
          Pesquisar
        </button>
      </div>
      <form v-on:submit.prevent="addDeliveryNote()">
        <div class="form-group">
          <label for="date">Data</label>
          <input
            type="date"
            class="form-control"
            id="date"
            v-model="date"
            required
          />
          <label for="tracking_number">Guia de Entrega</label>
          <input
            type="file"
            class="form-control"
            id="delivery_notes"
            v-on:change="handleFileUpload"
            required
          />
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">
            Adicionar Nota de Entrega
          </button>
        </div>
      </form>
    </div>
    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Guia de Entrega</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="note in allDeliviriesNotes" :key="note.id">
            <td>{{ formatDate(note.date) }}</td>
            <td>
              <button class="btn btn-primary" @click="openFile(note.document)">
                Guia de Transporte
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Loading,
  },
  data() {
    return {
      date_ini: '',
      date_fin: '',
      date: '',
      delivery_notes: '',
      loading: false,
      allDeliviriesNotes: [],
    };
  },
  methods: {
    async addDeliveryNote() {
      this.loading = true;
      console.log(this.delivery_notes);
      try {
        await this.$store.dispatch('addDeliveryNoteCTT', {
          date: this.date,
          file: this.delivery_notes,
        });
        this.getAllDeliveryNotes();
        this.$swal.fire({
          icon: 'success',
          title: 'Guia de Entrega Adicionada com Sucesso!',
          showConfirmButton: true,
        });
        await this.dayBefore();
        this.delivery_notes = '';
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: 'error',
          title: 'Erro ao adicionar Guia de Entrega!',
          showConfirmButton: true,
        });
      } finally {
        this.loading = false;
      }
    },
    handleFileUpload(event) {
      this.delivery_notes = event.target.files[0];
    },
    async getAllDeliveryNotes() {
      try {
        await this.$store.dispatch('getDeliveryNoteCTT', {
          date_ini: this.date_ini,
          date_fin: this.date_fin,
        });
        this.allDeliviriesNotes = this.getDeliveriesNotesCtt;
        console.log(this.allDeliviriesNotes);
      } catch (error) {
        console.log(error);
      }
    },
    openFile(file) {
      // window.open("data:application/pdf;base64,"+this.file, '_blank');
      let binary = atob(file);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blob = new Blob([new Uint8Array(array)], { type: 'application/pdf' });
      let url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('pt-PT', options);
    },
    dayBefore() {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      this.date = date.toISOString().split('T')[0];
    },
    setInitialDates() {
      const today = new Date();
      this.date_fin = today.toISOString().split('T')[0]; // Data inicial: hoje

      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
      this.date_ini = threeMonthsAgo.toISOString().split('T')[0]; // Data final: há 3 meses
    },
  },
  computed: {
    ...mapGetters(['getDeliveriesNotesCtt']),
  },
  async created() {
    await this.setInitialDates(); // Configura as datas inicial e final
    await this.getAllDeliveryNotes();
    await this.dayBefore();
  },
};
</script>
